import "./App.css";
import Home from "./pages/Home.jsx";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sites from "./pages/Sites.jsx";
import Contact from "./pages/Contact";
import Styling from "./pages/Styling";
import SitesNew from "./pages/SitesNew.jsx";


function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/styling" element={<Styling />} />
        <Route exact path="/sites" element={<Sites />} />
        <Route exact path="/About" element={<Contact />} />
        <Route exact path="/work" element={<SitesNew />} />
      </Routes>
    </Router>
  );
}

export default App;
