import HeavyMetal from "../../imgs/heavymetal.gif";
import Shining from "../../imgs/moonshining.gif";
import Upstairs1 from "../../imgs/Upstairs1.gif";
import Upstairs2 from "../../imgs/upstairs2.gif";
import Powerball from "../../imgs/powerball.gif";
import Dou from "../../imgs/dou.gif";
import Soso from "../../imgs/sososport.gif"
import Heaven from "../../imgs/heaven.gif"
import Aim from "../../imgs/aimtoplease.gif"
import Camo1 from "../../imgs/camo1.gif"
import Camo2 from "../../imgs/camo2.gif"
import Milkyway from "../../imgs/milkyway.gif"
import Tn from "../../imgs/tn.gif"
import Crown from "../../imgs/crown.gif"
import Collat2 from "../../imgs/collateraldamage2.gif"

const GraphData = [
  // {
  //   id: 14,
  //   image: Collat2,
  //   alt: "Collat",
  //   descrip: "Logo for Collateral Damage",
  // },
  {
    id: 7,
    image: Aim,
    alt: "Aim",
    descrip: "Graphic for Moonshine",
  },
  {
    id: 8,
    image: Heaven,
    alt: "Heaven",
    descrip: "Graphic for Felt",
  },
  {
    id: 10,
    image: Camo1,
    alt: "Heaven",
    descrip: "Camo for Felt",
  },
  {
    id: 13,
    image: Crown,
    alt: "Heaven",
    descrip: "Graphic for Felt",
  },
  {
    id: 11,
    image: Camo2,
    alt: "Heaven",
    descrip: "Camo for Felt",
  },
  {
    id: 1,
    image: HeavyMetal,
    alt: "Heavy Metal",
    descrip: "Graphic for Moonshine",
  },
  {
    id: 12,
    image: Milkyway,
    alt: "Heaven",
    descrip: "Graphic for Felt",
  },
  {
    id: 9,
    image: Soso,
    alt: "Sososport",
    descrip: "Graphic for Socialite Archive's 'Soso Sport'",
  },
  {
    id: 2,
    image: Shining,
    alt: "Moonshining",
    descrip: "Graphic for Moonshine",
  },
  {
    id: 6,
    image: Powerball,
    alt: "Powerball",
    descrip: "Graphic for Moonshine",
  },
  // {
  //   id: 13,
  //   image: Tn,
  //   alt: "Tn",
  //   descrip: "Social Assets for Footlocker's Nike Air Max TN 25th Anniversary",
  // },
  {
    id: 3,
    image: Upstairs1,
    alt: "Upstairs Bar",
    descrip: "Graphic for Upstairs Bar",
  },
  {
    id: 4,
    image: Upstairs2,
    alt: "Upstairs Bar",
    descrip: "Graphic for Upstairs Bar",
  },
  {
    id: 5,
    image: Dou,
    alt: "Art Basel",
    descrip:
      "Graphics for Bronx AAU Basketball Team. The team was put together with the intent of keeping NYC Youth off the streets.",
  },
];

export { GraphData };
