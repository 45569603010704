import React from 'react'
import PageTransition from "./PageTransition";
import A from "../imgs/a-green.gif";

const Contact = () => {
  return (
    <PageTransition>
      <div className="contact">
        <a href="/" className="sites-logo">
          <img src={A} alt="" className="logo-img" />
        </a>
        <div className="description-con">
          <p>
          Designer/Developer hailing from Brooklyn, NY with a degree in Emerging Media + Computer Science from Hunter College. Driven by passions for fashion, architecture, music, and coding, I apply my skill set to each respective area of design and development. <span className='email'>Email at a@adonis.earth.</span>
          </p>
        </div>
        {/* <div className="socials">
          <ul>
            <a href="https://www.linkedin.com/in/adonis-edwards/" target='_blank' className='socials-link'>
              <li>LinkedIn</li>
            </a>
            <a href="https://github.com/aaaadonis" target='_blank' className='socials-link'>
              <li>Github</li>
            </a>
          </ul>
        </div> */}
      </div>
    </PageTransition>
  );
}

export default Contact