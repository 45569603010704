import PushV from "../../imgs/push-vis.gif";
import PushL from "../../imgs/push-lyr.gif";
import Am1 from "../../imgs/am-gif15.gif";
import Am2 from "../../imgs/am-gif10.gif";
import Dlone1 from "../../imgs/dlonely-gif35.gif"
import Dlone2 from "../../imgs/dlonely-gif4.gif"
import DLone3 from "../../imgs/dlonely-text4.gif";
import DLone4 from "../../imgs/dlonely-text5.gif";
import YL from "../../imgs/yl-gif.gif"
import ilham3 from "../../imgs/ilham3.gif"
import Zayn from "../../imgs/zayn.gif"
import Dudey from "../../imgs/dudey-gif.gif"


const MusData = [
    {
      id: 1,
      image: Dlone1,
      alt: "Art Basel",
      descrip: "Visualizer for Destroy Lonely",
    },
    {
      id: 2,
      image: Dlone2,
      alt: "Basic Space",
      descrip: "Visualizer for Destroy Lonely",
    },
    {
      id: 3,
      image: PushV,
      alt: "Art Basel",
      descrip: "Creative Directed Lyric Videos for Pusha T's It's Almost Dry album",
      link: "https://www.youtube.com/watch?v=M6apli5j43Q",
    },
    {
      id: 4,
      image: PushL,
      alt: "Basic Space",
      descrip: "Creative Directed Visualizers for Pusha T's It's Almost Dry album",
      link: "https://www.youtube.com/watch?v=gXU5xsazHWc",
    },
    {
      id: 5,
      image: DLone3,
      alt: "Art Basel",
      descrip: "Text based Gif for Destroy Lonely",
    },
    {
      id: 6,
      image: DLone4,
      alt: "Basic Space",
      descrip: "Text based Gif for Destroy Lonely",
    },
    {
      id: 7,
      image: Am1,
      alt: "Art Basel",
      descrip: "Teaser for Amaarae's Co-Star video release",
    },
    {
      id: 8,
      image: Am2,
      alt: "Basic Space",
      descrip: "Teaser for Amaarae's Co-Star video release",
    },
  ];
  
  
  
  export { MusData };