import Dou from "../../imgs/dou.gif";
import Flyer from "../../imgs/flyer.gif";
import Vrdy from "../../imgs/vrdy.gif";
import Mirror from "../../imgs/mirror.gif";


const VarData = [
    {
      id: 1,
      image: Mirror,
      alt: "Art Basel",
      descrip: "Built and Developed a Digital Art Display for Physical Computing Final",
      link: "https://vimeo.com/656724782",
    },
    {
      id: 2,
      image: Flyer,
      alt: "Basic Space",
      descrip: "Art Direction and Graphic Design",
    }
  ];
  
  
  
  export { VarData };