import SH from "../../imgs/sh-gif.gif";
import Hardies from "../../imgs/hardies-gif.gif";
import PortV from "../../imgs/portv-cropped.gif";
import PortR from "../../imgs/portr-cropped.gif";
import PortT from "../../imgs/portthree-cropped.gif";
import Lum from "../../imgs/lum-cropped.gif";
import Form from "../../imgs/form-cropped.gif";
import Heart from "../../imgs/heart-gif.gif"
import Mirror from "../../imgs/mirror.gif";

const WebData = [
    {
      id: 1,
      image: SH,
      alt: "Art Basel",
      descrip: "Webstore Designed and Developed for Shit Happens",
      link: "https://shideaprogram.com/",
    },
    {
      id: 6,
      image: Form,
      alt: "Basic Space",
      descrip: "Fullstack Newsletter Application Designed and Developed for Shit Happens",
      link: "https://vercel.com/aaaadonis/sh/HQfGS2X8zCBf5VxrJLvbgiqvW1z6",
    },
    {
      id: 9,
      image: Mirror,
      alt: "Art Basel",
      descrip: "Built and Developed a Digital Art Display for Physical Computing Final",
      link: "https://vimeo.com/656724782",
    },
    {
      id: 5,
      image: Lum,
      alt: "Basic Space",
      descrip: "Web Portfolio Designed and Developed for Photographer/Model Lumia Nocito",
    },
    {
      id: 8,
      image: Hardies,
      alt: "Art Basel",
      descrip: "Webstore Designed and Developed for Hardies Hardware",
      link: "https://www.hardieshardware.com/",
    },
    {
      id: 7,
      image: Heart,
      alt: "Basic Space",
      descrip: "3D Site I built to host my Valentine's Day DJ Mix",
      link: "https://143.studio/",
    },
    {
      id: 2,
      image: PortV,
      alt: "Basic Space",
      descrip: "Portfolio progression 1",
      link: "https://aaaadonis.github.io/adonisedwards/",
    },
    {
      id: 3,
      image: PortR,
      alt: "Basic Space",
      descrip: "Portfolio progression 2",
    },
    {
      id: 4,
      image: PortT,
      alt: "Basic Space",
      descrip: "Portfolio progression 3",
      link: "https://www.hireadonis.xyz/",
    },
  ];
  
  
  
  export { WebData };