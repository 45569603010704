import React, { useState, useRef, useEffect } from "react";
import { Element } from "react-scroll";
import Ecom from "../imgs/ecom.gif";
import PortV from "../imgs/port-v.gif";
import PortR from "../imgs/port-r.gif";
import PortT from "../imgs/port-three.gif";
import Lum from "../imgs/lum.gif";
import A from "../imgs/a-green.gif";
import Dou from "../imgs/dou.gif";
import Flyer from "../imgs/flyer.gif";
import Form from "../imgs/form.gif";
import PushV from "../imgs/push-vis.gif";
import PushL from "../imgs/push-lyr.gif";
import Vrdy from "../imgs/vrdy.gif";
import Mirror from "../imgs/mirror.gif";
import PageTransition from "./PageTransition";
import Heart from "../imgs/heart-gif.gif"
import Am1 from "../imgs/am-gif15.gif";
import Am2 from "../imgs/am-gif10.gif";
import DLone3 from "../imgs/dlonely-text4.gif";
import DLone4 from "../imgs/dlonely-text5.gif";

const Sites = () => {
  const [isVisible, setIsVisible] = useState({});
  const refs = useRef([]);

  const handleVisibility = () => {
    refs.current.forEach((ref, index) => {
      if (ref.getBoundingClientRect().top < window.innerHeight) {
        setIsVisible((prev) => ({ ...prev, [index]: true }));
      }
    });
  };

  useEffect(() => {
    handleVisibility(); // Check visibility on initial load
    window.addEventListener("scroll", handleVisibility);
    return () => window.removeEventListener("scroll", handleVisibility);
  }, []);
  const sections = [
    {
      images: [<a href="https://shideaprogram.com/" target="_blank"><img src={Ecom} alt="" className="ecom-sites"/></a>],
      description: "Description: Webstores developed for Hardies Hardware and Shit Happens.",
      client: "Client: Hardies Hardware + Shit Happens",
    },
    {
      images: [<img src={Lum} alt="" />],
      description: "Description: Website for Model/Photographer Lumia Nocito. Designed and Developed by me. Coded using React/Styled Components and deployed on Vercel.",
      client: "Client: Lumia Nocito",
    },
    {
      images: [<img src={DLone3} alt="" className="push-gif" />, <img src={DLone4} alt="" className="push-gif" />],
      description: "Description: Text based gif/visualizers for Destroy Lonely.",
      client: "Client: After Dark Digital/Interscope",
    },
    {
      images: [<a href="https://143.studio/" target="_blank"><img src={Heart} alt="" /></a>],
      description: "Description: 3D Site built to host a Valentine's Day DJ Mix.",
      client: "Client: Personal",
    },
    {
      images: [
        <a href="https://aaaadonis.github.io/adonisedwards/" target="_blank"><img src={PortV} alt="" /></a>,
        <a href="https://www.adonis.earth/" target="_blank"><img src={PortR} alt="" /></a>,
        <a href="https://www.hireadonis.xyz/" target="_blank"><img src={PortT} alt="" /></a>
      ],
      description: "Description: Portfolio progression. Gifs link to each respective portfolio.",
      client: "Client: Personal",
    },
    {
      images: [<a href="https://sh-three.vercel.app/" target="_blank"><img src={Form} alt="" /></a>],
      description: "Description: Full Stack Newsletter Application",
      client: "Client: Shit Happens",
    },
    {
      images: [<img src={Am1} alt="" className="push-gif" />, <img src={Am2} alt="" className="push-gif" />],
      description: "Description: Cuts for Amaarae's Co-Star video release.",
      client: "Client: After Dark Digital/Interscope",
    },
    {
      images: [<a href="https://vimeo.com/656724782" target="_blank"><img src={Mirror} alt="" /></a>],
      description: "Description: Built and Developed Digital Art Display for Physical Computing course. Built by me and directed by me.",
      client: "Client: Physical Computing Final",
    },
    {
      images: [<img src={Dou} alt="" className="dou-img" />],
      description: "Description: Graphics done for Bronx AAU Basketball Team. The team was put together with the intent of keeping NYC Youth off the streets. Graphics placed on jerseys, shirts and shorts.",
      client: "Client: Urban Development Basketball",
    },
    {
      images: [
        <a href="https://www.youtube.com/watch?v=gXU5xsazHWc" target="_blank"><img src={PushL} alt="" className="push-gif" /></a>,
        <a href="https://www.youtube.com/watch?v=M6apli5j43Q" target="_blank"><img src={PushV} alt="" className="push-gif" /></a>
      ],
      description: "Description: Art Directed Visualizers and Lyric Videos for Pusha T's It's Almost Dry album.",
      client: "Client: Pusha T/G.O.O.D Music",
    },
    {
      images: [<img src={Flyer} alt="" />],
      description: "Description: Art Direction and Graphic Design",
      client: "Client: Personal",
    },
    {
      images: [<img src={Vrdy} alt="" />],
      description: "Description: Art Directed and Styled Wasted Youth x Afterbase collaboration",
      client: "Client: Verdy",
    },
  ];

  return (
    <PageTransition>
      <div className="sites-home first">
        <a href="/" className="sites-logo">
          <img src={A} alt="" className="logo-img" />
        </a>
        {sections.map((section, index) => (
          <Element name={`section-${index}`} key={index}>
            <div
              className="port-container"
              ref={(el) => (refs.current[index] = el)}
              style={{ opacity: isVisible[index] ? 1 : 0, transition: "opacity 1s" }}
            >
              <div className="port-sites">{section.images}</div>
              <ul className="words">
                <li>{section.description}</li>
                <li>{section.client}</li>
              </ul>
            </div>
          </Element>
        ))}
        <footer className="footer">
        {/* <a href="/styling">
            <p className="footer-p">Styling</p>
          </a> */}
          <a href="https://open.spotify.com/playlist/4DSWxnnZwXktixPLt9NW1G?si=Z-12oKW5SSCJMD5AIR99vA" target="_blank" className="playlists"><p className="footer-p">Playlists</p></a>
            
        </footer>
      </div>
    </PageTransition>
  );
};

export default Sites;
