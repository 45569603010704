import Dou from "../../imgs/dou.gif";
import Vrdy from "../../imgs/vrdy.gif";
import KidS from "../../imgs/kidsuper-gif.gif";
import YL from "../../imgs/yl-gif.gif"
import ilham3 from "../../imgs/ilham3.gif"
import Zayn from "../../imgs/zayn.gif"
import Dudey from "../../imgs/dudey-gif.gif"
import SSENSE from "../../imgs/SSENSE.gif"
import TNF from "../../imgs/tnf.gif"
import Tuned from "../../imgs/tuned.gif"
import Collat from "../../imgs/collateraldamage.gif"


const MoonData = [
  // {
  //   id: 11,
  //   image: Collat,
  //   alt: "Collateral Damage",
  //   descrip:
  //     "Creative Direction for Collateral Damage",
  // },
  {
    id: 10,
    image: SSENSE,
    alt: "Art Basel",
    descrip:
      "Art Direction for SSENSE's 20th Anniversary Event",
  },
  {
    id: 9,
    image: TNF,
    alt: "Art Basel",
    descrip:
      "Art Direction for The North Face's Nuptse Truck Giveback",
  },
  {
    id: 8,
    image: Tuned,
    alt: "Art Basel",
    descrip:
      "Art Direction for Foot Locker's Nike Air Max TN 25th Anniversary",
  },
  {
    id: 1,
    image: KidS,
    alt: "Art Basel",
    descrip:
      "Styling for KidSuper F/W 23 Boot Release",
  },
  {
    id: 6,
    image: Zayn,
    alt: "Art Basel",
    descrip: "Assisted Styled Zayn in his 'Love Like This' Music Video ",
    link: "https://www.youtube.com/watch?v=918oGJFtjjw",
  },
  {
    id: 4,
    image: ilham3,
    alt: "Art Basel",
    descrip: "Styling for ilham's 'BBL Freestyle' Music Video",
    link: "https://www.youtube.com/watch?v=QdDrxj7Pu-8",
  },
  {
    id: 2,
    image: Vrdy,
    alt: "Basic Space",
    descrip: "Art Direction for Wasted Youth x Afterbase collaboration",
    link: "https://hypebeast.com/2022/2/verdy-wasted-youth-afterbase-third-collaboration-2022",
  },
  {
    id: 5,
    image: YL,
    alt: "Art Basel",
    descrip: "Styling for NGeeYL's Press Photos, Client 300 Entertainment",
  },
  {
    id: 7,
    image: Dudey,
    alt: "Art Basel",
    descrip: "Styling for DudeyLo's Press Photos, Client Cinematic Music Group",
  },
];

export { MoonData };
