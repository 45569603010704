import React, { useState, useEffect } from "react";
import "../App.css";
import PageTransition from "./PageTransition";
import AHome from "../imgs/art-dir-home.png";

const Home = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    // Trigger the fade-in effect shortly after the component is mounted
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 100);  // You can adjust this delay if needed

    return () => clearTimeout(timer); // Cleanup the timer if component is unmounted
  }, []);

  return (
    <div className="home">
      <div className="title">
        <a href="/work">
          <img src={AHome} alt="" className="logo-home" />
        </a>
      </div>
      <div className="descrip">
        <div className="descrip-item">
          <a href="/work" className={`work-home ${fadeIn ? "fade-in" : ""}`}>
            Work
          </a>
        </div>
        <div className="descrip-item">Brooklyn, New York</div>
        <div className="descrip-item">
        <a href="/about" className={`work-home ${fadeIn ? "fade-in" : ""}`}>About</a>
        </div>
      </div>
    </div>
  );
};

export default Home;
