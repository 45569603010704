import React, { useState } from "react";
import "./sites.css";
import ProjectModal from "./ProjectModal";
import { VarData } from "./sitesdata/Various";
import { MoonData } from "./sitesdata/Moonshine";
import { MusData } from "./sitesdata/Music";
import PersonalGame from "./sitesdata/Personal";
import { WebData } from "./sitesdata/Websites";
import { GraphData } from "./sitesdata/Graphics";
import A from "../imgs/a-green.gif";
import Gif from "../imgs/full-gif.gif";
import { ColData } from "./sitesdata/Collateral";

const Sites = () => {
  const [sectionShowing, setSectionShowing] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleMenuClick = (section) => {
    if (sectionShowing === section) {
      setSectionShowing("");
    } else {
      setSectionShowing(section);
      if (true) {
        scrollToTop();
      }
    }
  };

  const openModal = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="cont">
      <a href="/" className="sites-logo">
        <img src={A} alt="" className="logo-img" />
      </a>
      <div className="content">
        <div className="menuWrapper">
          <h1
            className={`menuItems exhibitions ${
              sectionShowing === "graphics" ? "active" : ""
            }`}
            onClick={() => handleMenuClick("graphics")}
          >
            Design
          </h1>
          <h1
            className={`menuItems interviews ${
              sectionShowing === "moonshine" ? "active" : ""
            }`}
            onClick={() => handleMenuClick("moonshine")}
          >
            Fashion
          </h1>
          <h1
            className={`menuItems exhibitions ${
              sectionShowing === "websites" ? "active" : ""
            }`}
            onClick={() => handleMenuClick("websites")}
          >
            Development
          </h1>
          <h1
            className={`menuItems photography ${
              sectionShowing === "music" ? "active" : ""
            }`}
            onClick={() => handleMenuClick("music")}
          >
            Music
          </h1>
          <h1
            className={`menuItems photography ${
              sectionShowing === "Collateral" ? "active" : ""
            }`}
            onClick={() => handleMenuClick("Collateral")}
          >
            Collateral Damage
          </h1>
          <h1
            className={`menuItems honors ${
              sectionShowing === "personal" ? "active" : ""
            }`}
            // onClick={() => handleMenuClick("personal")} // Commented out or removed
          >
            {/* Wrap Playlists with an anchor tag linking to Spotify */}
            <a
              href="https://open.spotify.com/user/renaissance_don?si=EQ-jnU8lTZmIE7a24WNgzw"
              target="_blank"
              rel="noopener noreferrer"
            >
              Playlists
            </a>
          </h1>
        </div>
        <div className="contentDisplay" style={{ marginLeft: "6rem" }}>
          {sectionShowing === "" && (
            <div>
              <img src={Gif} alt="" className="site-img home-gif" />
            </div>
          )}
          {sectionShowing === "websites" && (
            <div>
              {WebData.map((obj, ind) => {
                return (
                  // <div className="textContent" key={project.id}>
                  //   <div className="contents">
                  //     <img
                  //       src={project.image}
                  //       alt="img"
                  //       className="site-img"
                  //       onClick={() => openModal(project)} // Add this line
                  //     />
                  //     <p className="site-descrip">{project.descrip}</p>
                  //   </div>
                  // </div>
                  <div className="textContent" key={obj.id}>
                    <div className="contents">
                      <a href={obj.link} target="_blank">
                        <img src={obj.image} alt="img" className="site-img" />
                      </a>
                      <p className="site-descrip">{obj.descrip}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {sectionShowing === "music" && (
            <div>
              {MusData.map((obj, ind) => {
                return (
                  <div className="textContent" key={obj.id}>
                    <div className="contents">
                      <a href={obj.link} target="_blank">
                        <img src={obj.image} alt="img" className="site-img" />
                      </a>
                      <p className="site-descrip">{obj.descrip}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {sectionShowing === "graphics" && (
            <div>
              {GraphData.map((obj, ind) => {
                return (
                  <div className="textContent" key={obj.id}>
                    <div className="contents">
                      <a href={obj.link} target="_blank">
                        <img src={obj.image} alt="img" className="site-img" />
                      </a>
                      <p className="site-descrip">{obj.descrip}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {sectionShowing === "moonshine" && (
            <div>
              {MoonData.map((obj, ind) => {
                return (
                  <div className="textContent" key={obj.id}>
                    <div className="contents">
                      <a href={obj.link} target="_blank">
                        <img src={obj.image} alt="img" className="site-img" />
                      </a>
                      <p className="site-descrip">{obj.descrip}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {sectionShowing === "various" && (
            <div>
              {VarData.map((obj, ind) => {
                return (
                  <div className="textContent" key={obj.id}>
                    <div className="contents">
                      <a href={obj.link} target="_blank">
                        <img src={obj.image} alt="img" className="site-img" />
                      </a>
                      <p className="site-descrip">{obj.descrip}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {sectionShowing === "Collateral" && (
            <div>
              <p className="textContent contents cd-descrip">Collateral Damage is a bold, visually striking project that explores the balance between vulnerability and resilience. With the conception, design, development, and visual direction done myself, each piece of content reflects a journey through chaos and transformation. The project brings together vivid imagery and storytelling to challenge conventional perspectives on personal growth and destruction.</p>
              {ColData.map((obj, ind) => {
                return (
                  <div className="textContent" key={obj.id}>
                    <div className="contents">
                      <a href={obj.link} target="_blank">
                        <img src={obj.image} alt="img" className="site-img" />
                      </a>
                      <p className="site-descrip">{obj.descrip}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

        </div>
      </div>
      {isModalOpen && (
        <ProjectModal project={selectedProject} onClose={closeModal} />
      )}
    </div>
  );
};

export default Sites;
