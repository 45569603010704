
import React from 'react';

const ProjectModal = ({ project, onClose }) => {
    if (!project) return null;

    return (
        <div className={`modal ${project ? 'open' : ''}`}>
            {/* Close button with "X" */}
            <button onClick={onClose} className="close-modal">x</button>
            
            <div className="modal-content">
                {/* Project details */}
                <a href={project.link} target="_blank"><img src={project.image} alt={project.title} className="modal-image" /></a>
                <h2>{project.title}</h2>
                <p className='site-descrip'>{project.descrip}</p>
            </div>
        </div>
    );
};

export default ProjectModal;


