import React from "react";
import YL1 from "../imgs/yl-1.jpeg";
import YL2 from "../imgs/yl-2.jpg";
import YL3 from "../imgs/yl-3.jpg";
import il1 from "../imgs/ilham1.jpeg";
import il2 from "../imgs/ilham2.jpeg";
import vrdy1 from "../imgs/vrdy.png";
import A from "../imgs/a.gif";
import '../styling.css'

const Styling = () => {
  const firstImage = YL1;
  const otherImages = [
    {
      src: YL2,
      key: 2,
    },
    {
      src: YL3,
      key: 3,
    },
    {
      src: il1,
      key: 4,
    },
    {
      src: il2,
      key: 5,
    },
    {
      src: vrdy1,
      key: 6,
    },
  ];

  return (
      <div className="">
        <a href="/" className="sites-logo">
          <img src={A} alt="" className="logo-img" />
        </a>
        <div className="container">
        <div className="images-wrapper">
          <img src={firstImage} alt="" className="first-image" />
          <div className="other-images">
            {otherImages.map((image) => (
              <img
                key={image.key}
                src={image.src}
                alt=""
                className="other-image"
              />
            ))}
          </div>
        </div>
        </div>

        <footer className="footer">
          <a href="/contact">
            <p className="footer-p">Contact</p>
          </a>
        </footer>
      </div>
  );
};

export default Styling;
