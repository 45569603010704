
import CDChicago from "../../imgs/cdchicago1.gif";
import CDChicago2 from "../../imgs/cdchicagophotos.gif";
import CDScarecrow from "../../imgs/cdscarecrow.gif";
import CDEditorial from "../../imgs/cdtire.gif";
import CDLogo from "../../imgs/collatlogo.png";
import CDScans from "../../imgs/cd-scans.gif";
import CDWhite from "../../imgs/cdchicagowhite.gif";


const ColData = [
  {
    id: 1,
    image: CDLogo,
    alt: "Logo Design",

  },
  {
    id: 6,
    image: CDScans,
    alt: "Logo Design",

  },
  {
    id: 2,
    image: CDEditorial,
    alt: "Basic Space",

  },  {
    id: 7,
    image: CDWhite,
    alt: "Basic Space",

  },
  {
    id: 4,
    image: CDScarecrow,
    alt: "Basic Space",

  },
  {
    id: 3,
    image: CDChicago,
    alt: "Basic Space",

  },
  {
    id: 5,
    image: CDChicago2,
    alt: "Basic Space",

  },
];

export { ColData };
